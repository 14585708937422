import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { yearsToFullText } from "../../../utils"
import { UserContext } from "../../../context/UserContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PracticeStringField from "./PracticeStringField"
import PracticeSelectField from "./PracticeSelectField"
import PracticeApproval from "./PracticeApproval"
import MathMarkdown from "../../helpers/MathMarkdown"
import PracticeSwitchType from "./PracticeSwitchType"

const PracticeCard = ({ practice }) => {
  const {
    user: { userType, userID },
  } = useContext(UserContext)
  const { t, i18n } = useTranslation("data")
  const {
    students: { dict: stDict },
    programmes: { dict: prDict },
  } = useContext(CommonDataContext)

  const [innerPractice, setInnerPractice] = useState(practice)

  useEffect(() => {
    setInnerPractice(practice)
  }, [practice])

  const [showFull, setShowFull] = useState(userType !== "admin")
  const [showLogs, setShowLogs] = useState(false)

  const student = stDict?.[innerPractice?.studentID]

  const statusClass =
    innerPractice?.status === "awaiting"
      ? "has-text-warning"
      : innerPractice?.status === "accepted"
        ? "has-text-success"
        : "has-text-danger"

  const categoryClass =
    innerPractice?.category === "red"
      ? "has-text-danger"
      : innerPractice?.category === "yellow"
        ? "has-text-warning"
        : innerPractice?.category === "blue"
          ? "has-text-link"
          : "has-text-success"

  const fullInfo = (
    <>
      <hr />

      <div className="columns">
        <div className="column">
          <p>
            <strong>{t("transfer.studentYos")}</strong>
            <br />
            {yearsToFullText[i18n.language][student?.yearOfStudy]}
          </p>
        </div>

        <div className="column">
          <p>
            <strong>{t("application.studentProgramme")}</strong>
            <br />
            {prDict?.[student?.programmeID]?.title?.[i18n.language]}
          </p>
        </div>

        <div className="column">
          <p>
            <strong>{t("practice.status")}</strong>
            <br />
            <span className={statusClass}>
              {t(`transfer.${innerPractice?.status}Status`)}
            </span>
          </p>
        </div>
      </div>

      {userType !== "student" && innerPractice?.orgType !== "assistance" ? (
        <div className="columns">
          <div className="column">
            <PracticeSelectField
              practice={innerPractice}
              setPractice={setInnerPractice}
              field="hsePracticeLeaderID"
            />
          </div>

          <div className="column">
            <PracticeStringField
              practice={innerPractice}
              setPractice={setInnerPractice}
              field="startDate"
            />
          </div>

          <div className="column">
            <PracticeStringField
              practice={innerPractice}
              setPractice={setInnerPractice}
              field="endDate"
            />
          </div>
        </div>
      ) : null}

      {(innerPractice?.orgType != "assistance" && userType === "admin") ||
      (userType === "projectLeader" &&
        userID === innerPractice?.hsePracticeCuratorID) ? (
        <div className="columns">
          <div className="column">
            <PracticeSelectField
              practice={innerPractice}
              setPractice={setInnerPractice}
              field="hsePracticeCuratorID"
            />
          </div>

          <div className="column"></div>
          <div className="column"></div>
        </div>
      ) : null}

      {innerPractice?.orgType === "assistance" ? (
        <div className="columns">
          <div className="column">
            <PracticeStringField
              practice={innerPractice}
              setPractice={setInnerPractice}
              field="course"
            />
          </div>

          <div className="column">
            <PracticeStringField
              practice={innerPractice}
              setPractice={setInnerPractice}
              field="startDate"
            />
          </div>

          <div className="column">
            <PracticeStringField
              practice={innerPractice}
              setPractice={setInnerPractice}
              field="endDate"
            />
          </div>
        </div>
      ) : null}

      {["hse", "assistance"].includes(innerPractice?.orgType) ? (
        <div className="columns">
          <div className="column">
            <PracticeStringField
              practice={innerPractice}
              setPractice={setInnerPractice}
              field="hseDepartment"
            />
          </div>
        </div>
      ) : null}

      {innerPractice?.orgType === "external" ? (
        <div className="columns">
          <div className="column">
            <PracticeStringField
              practice={innerPractice}
              setPractice={setInnerPractice}
              field="orgTitle"
            />
          </div>

          <div className="column">
            {innerPractice?.orgLocation === "rf" ? (
              <PracticeStringField
                practice={innerPractice}
                setPractice={setInnerPractice}
                field="inn"
              />
            ) : (
              <PracticeStringField
                practice={innerPractice}
                setPractice={setInnerPractice}
                field="country"
              />
            )}
          </div>

          <div className="column">
            <PracticeStringField
              practice={innerPractice}
              setPractice={setInnerPractice}
              field="orgAddress"
            />
          </div>
        </div>
      ) : null}

      {innerPractice?.orgType === "hse"
        ? userType === "admin" || (
            <div className="columns">
              <div className="column">
                <PracticeSelectField
                  practice={innerPractice}
                  setPractice={setInnerPractice}
                  field="hsePracticeLeaderID"
                />
              </div>
            </div>
          )
        : null}

      {innerPractice?.orgType === "external" ? (
        <>
          <div className="message is-link">
            <div className="message-header">{t("practice.leaderTitle")}</div>
            <div className="message-body">
              <div className="columns">
                <div className="column">
                  <PracticeStringField
                    practice={innerPractice}
                    setPractice={setInnerPractice}
                    field="leaderFullName"
                    labelCode="fullName"
                  />
                </div>

                <div className="column">
                  <PracticeStringField
                    practice={innerPractice}
                    setPractice={setInnerPractice}
                    field="leaderPosition"
                    labelCode="position"
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <PracticeStringField
                    practice={innerPractice}
                    setPractice={setInnerPractice}
                    field="leaderEmail"
                    labelCode="email"
                  />
                </div>

                <div className="column">
                  <PracticeStringField
                    practice={innerPractice}
                    setPractice={setInnerPractice}
                    field="leaderPhone"
                    labelCode="phone"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="message is-link">
            <div className="message-header">{t("practice.acceptorTitle")}</div>
            <div className="message-body">
              <div className="columns">
                <div className="column">
                  <PracticeStringField
                    practice={innerPractice}
                    setPractice={setInnerPractice}
                    field="acceptorFullName"
                    labelCode="fullName"
                  />
                </div>

                <div className="column">
                  <PracticeStringField
                    practice={innerPractice}
                    setPractice={setInnerPractice}
                    field="acceptorPosition"
                    labelCode="position"
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <PracticeStringField
                    practice={innerPractice}
                    setPractice={setInnerPractice}
                    field="acceptorEmail"
                    labelCode="email"
                  />
                </div>

                <div className="column">
                  <PracticeStringField
                    practice={innerPractice}
                    setPractice={setInnerPractice}
                    field="acceptorPhone"
                    labelCode="phone"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="message is-link">
            <div className="message-header">{t("practice.contactTitle")}</div>
            <div className="message-body">
              <div className="columns">
                <div className="column">
                  <PracticeStringField
                    practice={innerPractice}
                    setPractice={setInnerPractice}
                    field="contactFullName"
                    labelCode="fullName"
                  />
                </div>

                <div className="column">
                  <PracticeStringField
                    practice={innerPractice}
                    setPractice={setInnerPractice}
                    field="contactPosition"
                    labelCode="position"
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <PracticeStringField
                    practice={innerPractice}
                    setPractice={setInnerPractice}
                    field="contactEmail"
                    labelCode="email"
                  />
                </div>

                <div className="column">
                  <PracticeStringField
                    practice={innerPractice}
                    setPractice={setInnerPractice}
                    field="contactPhone"
                    labelCode="phone"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <PracticeStringField
                practice={innerPractice}
                setPractice={setInnerPractice}
                field="incomingLetterNum"
              />
            </div>

            <div className="column">
              <PracticeStringField
                practice={innerPractice}
                setPractice={setInnerPractice}
                field="outcomingLetterNum"
              />
            </div>
          </div>
        </>
      ) : null}
    </>
  )

  return (
    <div className="box">
      <p className="subtitle is-5">
        {userType === "student" || (
          <FontAwesomeIcon
            icon="ellipsis-v"
            className={categoryClass}
            size="lg"
          />
        )}
        &nbsp;
        <button
          className={
            "button is-small is-rounded " +
            (innerPractice?.isInternal
              ? "is-link is-outlined"
              : innerPractice?.incomingLetterNum?.length > 0 &&
                  innerPractice?.outcomingLetterNum?.length <= 0
                ? "is-success"
                : "is-dark is-outlined")
          }
        >
          {innerPractice?.orgType === "hse"
            ? "HSE"
            : innerPractice?.orgType === "external"
              ? "EXT"
              : "TA"}
        </button>
        &nbsp;
        <button className="button is-small is-rounded is-outlined is-dark">
          {innerPractice?.practiceYear}
        </button>
        &nbsp;
        {student?.fullName} (
        <a href={`mailto:${student?.email}`}>{student?.email}</a>)
        <span className="is-pulled-right">
          {!(userType === "admin" && innerPractice?.logs?.length > 0) || (
            <>
              <span
                className={"pointer " + (showLogs ? "has-text-grey" : "")}
                onClick={() => setShowLogs(!showLogs)}
              >
                <FontAwesomeIcon icon="history" size="lg" />
              </span>
              &nbsp;
            </>
          )}
          <span
            className={"pointer " + (showFull ? "has-text-grey" : "")}
            onClick={() => setShowFull(!showFull)}
          >
            <FontAwesomeIcon icon={!showFull ? "eye" : "eye-slash"} size="lg" />
          </span>
        </span>
      </p>

      {!showFull || fullInfo}

      <PracticeSwitchType
        practice={innerPractice}
        setPractice={setInnerPractice}
      />

      <PracticeApproval
        practice={innerPractice}
        setPractice={setInnerPractice}
      />

      {innerPractice?.status !== "declined" || (
        <div className="message is-danger">
          <div className="message-body">{innerPractice?.declineReason}</div>
        </div>
      )}

      {!showLogs || (
        <>
          <hr />
          <p className="title is-6">
            История изменений&nbsp;
            <span
              className="has-text-grey pointer"
              onClick={() => setShowLogs(false)}
            >
              (скрыть)
            </span>
          </p>
          <div className="content" style={{ whiteSpace: "pre-wrap" }}>
            <MathMarkdown>{innerPractice?.logs}</MathMarkdown>
          </div>
        </>
      )}
    </div>
  )
}

export default PracticeCard
