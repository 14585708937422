import { useTranslation } from "react-i18next"
import { Link, Route, Switch, useRouteMatch } from "react-router-dom"
import ProjectsFilterContextProvider from "../../context/ProjectsFilterContext"
import ProposalsContextProvider from "../../context/ProposalsContext"
import NavBar from "../helpers/NavBar"
import ProjectsPageView from "./divsionManager/ProjectsPageView"
import LoadsPageView from "./divsionManager/LoadsPageView"
import ProposalsPageView from "./divsionManager/ProposalsPageView"
import PlacesPageView from "./divsionManager/PlacesPageView"
import HomeView from "./HomeView"
import PracticeList from "../data/practice/PracticeList"

const DivisionManagerWorkspace = () => {
  const { t } = useTranslation("ws")
  const { path, url } = useRouteMatch()

  return (
    <>
      <NavBar>
        <Link className="navbar-item" to={url + "/proposals"}>
          {t("menu.proposals")}
        </Link>

        <Link className="navbar-item" to={url + "/projects"}>
          {t("menu.adminProjects")}
        </Link>

        <Link className="navbar-item" to={url + "/loads"}>
          {t("menu.leaderLoads")}
        </Link>

        <Link className="navbar-item" to={url + "/places"}>
          {t("menu.coursePlaces")}
        </Link>

        <Link className="navbar-item" to={url + "/practice"}>
          {t("menu.practice")}
        </Link>
      </NavBar>

      <div className="section">
        <div className="container">
          <ProposalsContextProvider userType="divisionManager">
            <Switch>
              <Route exact path={path}>
                <HomeView />
              </Route>

              <Route path={path + "/proposals"}>
                <ProposalsPageView />
              </Route>

              <Route path={path + "/projects"}>
                <ProjectsFilterContextProvider>
                  <ProjectsPageView />
                </ProjectsFilterContextProvider>
              </Route>

              <Route path={path + "/loads"}>
                <LoadsPageView />
              </Route>

              <Route path={path + "/places"}>
                <PlacesPageView />
              </Route>

              <Route path={path + "/practice"}>
                <PracticeList />
              </Route>
            </Switch>
          </ProposalsContextProvider>
        </div>
      </div>
    </>
  )
}

export default DivisionManagerWorkspace
