import axios from "axios"
import Select from "react-select"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { API_URL, messageToToasts } from "../../../utils"
import { UserContext } from "../../../context/UserContext"
import { toast } from "react-toastify"
import PracticeCard from "./PracticeCard"
import PracticePersonDataForm from "./PracticePersonDataForm"
import { CommonDataContext } from "../../../context/CommonDataContext"

const allowedFixedPL = [
  "608bf1ef7406ba98c2ffd05f",
  "676bfb001649ca5d7e777603",
  "60741a07097ef9ab28525d11",
  "608473b7379903deb09cb962",
  "606efded097ef9ab28525c05",
  "609cd0ef5de783672046334e",
  "6071731b097ef9ab28525c89",
  "606c4a6f097ef9ab28525b85",
]

const PracticeNew = () => {
  const { t, i18n } = useTranslation("data")
  const {
    user: { userID },
  } = useContext(UserContext)
  const {
    divisions: { options: divisionOptions, loading: divisionsLoading },
    projectLeaders: { dict: plDict },
  } = useContext(CommonDataContext)

  const [nr, setNR] = useState(true)
  const [hasPractice, setHasPractice] = useState(true)
  const [practice, setPractice] = useState(null)
  const [orgType, setOrgType] = useState("hse")
  const [hseHasAgreement, setHseHasAgreement] = useState(false)
  const [hseDepartment, setHseDepartment] = useState("")
  const [hseDepartmentOption, setHseDepartmentOption] = useState("")
  const [orgLocation, setOrgLocation] = useState("rf")
  const [orgAddress, setOrgAddress] = useState("")
  const [orgTitle, setOrgTitle] = useState("")
  const [course, setCourse] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [inn, setInn] = useState("")
  const [country, setCountry] = useState("")
  const [personsForm, setPersonsForm] = useState({
    leaderFullName: "",
    leaderPosition: "",
    leaderEmail: "",
    leaderPhone: "",
    acceptorFullName: "",
    acceptorPosition: "",
    acceptorEmail: "",
    acceptorPhone: "",
    contactFullName: "",
    contactPosition: "",
    contactEmail: "",
    contactPhone: "",
  })

  useEffect(() => {
    const getPractice = async () => {
      const resp = await axios.get(
        `${API_URL}/practice/get?studentID=${userID}`
      )

      const { hasPractice, practice } = resp.data
      setHasPractice(hasPractice)
      setPractice(practice)
    }

    if (nr) {
      getPractice()
      setNR(false)
    }
  }, [userID, nr])

  const sendForm = async () => {
    if (orgType == "hse" && !personsForm?.hsePracticeLeaderID?.value) {
      return toast.error("Необходимо выбрать руководителя")
    }

    try {
      const data = {
        studentID: userID,
        isInternal: orgType === "hse" || orgType == "assistance",
        orgType,
        hseDepartment:
          orgType === "hse" ? hseDepartment : hseDepartmentOption?.label,
        hseDepartmentID: hseDepartmentOption?.value,
        orgTitle,
        orgLocation,
        orgAddress,
        inn,
        country,
        format: "offline",
        ...personsForm,
        hsePracticeLeaderID: personsForm?.hsePracticeLeaderID?.value,
        startDate,
        endDate,
        course,
      }

      console.log(data)

      const resp = await axios.post(`${API_URL}/practice/add`, data)
      const { status, message } = resp.data

      if (status === "failed") {
        return messageToToasts(message, i18n.language)
      }

      setNR(true)
    } catch (_) {
      toast.error(
        "System error (or internet connection missing)! Try to reload page"
      )
    }
  }

  const plOptions = Object.values(plDict).map(pl => ({
    value: pl.id,
    label: pl.fullName,
  }))

  return (
    <>
      <div className="box has-text-right title is-4">{t("practice.title")}</div>

      {!hasPractice || <PracticeCard practice={practice} />}

      {hasPractice || (
        <div className="box">
          <div className="columns">
            <div className="column">
              <label className="label">{t("practice.orgType")}</label>
              <div className="field has-addons">
                <div className="control is-expanded">
                  <button
                    onClick={() => setOrgType("hse")}
                    className={
                      "button is-fullwidth " +
                      (orgType === "hse" ? "is-link" : "")
                    }
                  >
                    {t("practice.hse")}
                  </button>
                </div>

                <div className="control is-expanded">
                  <button
                    onClick={() => setOrgType("assistance")}
                    className={
                      "button is-fullwidth " +
                      (orgType === "assistance" ? "is-link" : "")
                    }
                  >
                    {t("practice.assistance")}
                  </button>
                </div>

                <div className="control is-expanded">
                  <button
                    onClick={() => setOrgType("external")}
                    className={
                      "button is-fullwidth " +
                      (orgType === "external" ? "is-link" : "")
                    }
                  >
                    {t("practice.external")}
                  </button>
                </div>
              </div>
            </div>

            <div className="column">
              {orgType === "external" ? (
                <>
                  <label className="label">{t("practice.orgLocation")}</label>
                  <div className="field has-addons">
                    <div className="control is-expanded">
                      <button
                        onClick={() => setOrgLocation("rf")}
                        className={
                          "button is-fullwidth " +
                          (orgLocation === "rf" ? "is-link" : "")
                        }
                      >
                        {t("practice.rf")}
                      </button>
                    </div>
                    <div className="control is-expanded">
                      <button
                        onClick={() => setOrgLocation("non-rf")}
                        className={
                          "button is-fullwidth " +
                          (orgLocation === "non-rf" ? "is-link" : "")
                        }
                      >
                        {t("practice.non-rf")}
                      </button>
                    </div>
                  </div>
                </>
              ) : null}

              {orgType === "assistance" ? (
                <>
                  <div className="field">
                    <div className="control">
                      <label className="label">
                        {t("practice.hseDepartment")}
                      </label>
                      <Select
                        options={divisionOptions[i18n.language]}
                        value={hseDepartmentOption}
                        onChange={option => setHseDepartmentOption(option)}
                        placeholder="Выберите департамент НИУ ВШЭ..."
                        isLoading={divisionsLoading}
                        isClearable
                      />
                      <p className="help">{t("required")}</p>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          <hr />

          {orgType === "hse" ? (
            <div className="field">
              <div className="control">
                <label className="label">{t("practice.hseDepartment")}</label>
                <input
                  value={hseDepartment}
                  onChange={e => setHseDepartment(e.target.value)}
                  className="input"
                  type="text"
                />
                <p className="help">{t("required")}</p>
              </div>
            </div>
          ) : null}
          {orgType === "external" ? (
            <>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <label className="label">{t("practice.orgTitle")}</label>
                      <input
                        value={orgTitle}
                        onChange={e => setOrgTitle(e.target.value)}
                        className="input"
                        type="text"
                      />
                      <p className="help">
                        {t("required") + ", " + t("practice.orgTitleHelp")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <label className="label">
                        {t(
                          `practice.${orgLocation === "rf" ? "inn" : "country"}`
                        )}
                      </label>
                      <input
                        value={orgLocation === "rf" ? inn : country}
                        onChange={e =>
                          orgLocation === "rf"
                            ? setInn(e.target.value)
                            : setCountry(e.target.value)
                        }
                        className="input"
                        type="text"
                      />
                      <p className="help">{t("required")}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <label className="label">
                        {t("practice.orgAddress")}
                      </label>
                      <input
                        value={orgAddress}
                        onChange={e => setOrgAddress(e.target.value)}
                        className="input"
                        type="text"
                      />
                      <p className="help">{t("required")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {orgType === "assistance" ? (
            <div className="columns">
              <div className="column">
                <div className="field">
                  <div className="control">
                    <label className="label">{t("practice.course")}</label>
                    <input
                      value={course}
                      onChange={e => setCourse(e.target.value)}
                      placeholder="Линейная алгебра"
                      className="input"
                      type="text"
                    />
                    <p className="help">{t("required")}</p>
                  </div>
                </div>
              </div>

              <div className="column">
                <div className="field">
                  <div className="control">
                    <label className="label">{t("practice.startDate")}</label>
                    <input
                      value={startDate}
                      onChange={e => setStartDate(e.target.value)}
                      placeholder="dd.mm.yyyy (i.e. 01.09.2024)"
                      className="input"
                      type="text"
                    />
                    <p className="help">{t("required")}</p>
                  </div>
                </div>
              </div>

              <div className="column">
                <div className="field">
                  <div className="control">
                    <label className="label">{t("practice.endDate")}</label>
                    <input
                      value={endDate}
                      onChange={e => setEndDate(e.target.value)}
                      className="input"
                      placeholder="dd.mm.yyyy (i.e. 24.12.2024)"
                      type="text"
                    />
                    <p className="help">{t("required")}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {orgType !== "hse" || (
            <>
              <hr />
              <div className="field">
                <div className="control">
                  <label className="label">
                    {t("practice.agreementWithLeader")}
                  </label>
                  <div className="field has-addons">
                    <div className="control is-expanded">
                      <button
                        onClick={() => setHseHasAgreement(true)}
                        className={
                          "button is-fullwidth " +
                          (hseHasAgreement ? "is-link" : "")
                        }
                      >
                        {t("practice.hseHasAgreement")}
                      </button>
                    </div>
                    <div className="control is-expanded">
                      <button
                        onClick={() => setHseHasAgreement(false)}
                        className={
                          "button is-fullwidth " +
                          (!hseHasAgreement ? "is-link" : "")
                        }
                      >
                        {t("practice.hseSetFaculty")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {hseHasAgreement ? (
                <div className="columns">
                  <div className="column">
                    <div className="field">
                      <div className="control">
                        <Select
                          options={plOptions}
                          value={personsForm?.hsePracticeLeaderID}
                          onChange={option =>
                            setPersonsForm({
                              ...personsForm,
                              hsePracticeLeaderID: option,
                            })
                          }
                          placeholder="Выберите руководителя..."
                          isClearable
                        />
                        <p className="help">{t("required")}</p>
                      </div>
                    </div>
                  </div>

                  <div className="column">
                    <article className="message is-warning">
                      <div className="message-body">
                        <p>{t("practice.agreementHelp.p1")}</p>

                        <br />

                        <p>{t("practice.agreementHelp.p2")}</p>

                        <br />

                        <p>{t("practice.agreementHelp.p3")}</p>
                      </div>
                    </article>
                  </div>
                </div>
              ) : (
                <div className="columns">
                  <div className="column">
                    <div className="field">
                      <div className="control">
                        <Select
                          options={(plOptions || []).filter(option =>
                            allowedFixedPL.includes(option.value)
                          )}
                          value={personsForm?.hsePracticeLeaderID}
                          onChange={option =>
                            setPersonsForm({
                              ...personsForm,
                              hsePracticeLeaderID: option,
                            })
                          }
                          placeholder="Выберите руководителя..."
                          isClearable
                        />
                        <p className="help">{t("required")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {orgType !== "external" || (
            <>
              <PracticePersonDataForm
                personType="leader"
                form={personsForm}
                setForm={setPersonsForm}
              />

              <PracticePersonDataForm
                personType="acceptor"
                form={personsForm}
                setForm={setPersonsForm}
              />

              <PracticePersonDataForm
                personType="contact"
                form={personsForm}
                setForm={setPersonsForm}
              />
            </>
          )}

          <div className="field is-grouped is-grouped-right">
            <div className="control">
              <button onClick={sendForm} className="button is-link">
                {t("practice.send")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PracticeNew
